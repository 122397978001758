export const GET_TASKS = "GET_TASKS";
export const DELETE_TASK = "DELETE_TASK";
export const ADD_TASK = "ADD_TASK";
export const GET_TASK = "GET_TASK";
export const UPDATE_TASK = "UPDATE_TASK";

export const GET_COMPANY_USERS = "GET_COMPANY_USERS";
export const DELETE_COMPANY_USER = "DELETE_COMPANY_USER";
export const ADD_COMPANY_USER = "ADD_COMPANY_USER";
export const GET_COMPANY_USER = "GET_COMPANY_USER";
export const UPDATE_COMPANY_USER = "UPDATE_COMPANY_USER";

export const GET_PROJECTS = "GET_PROJECTS";
export const DELETE_PROJECT = "DELETE_PROJECT";
export const ADD_PROJECT = "ADD_PROJECT";
export const GET_PROJECT = "GET_PROJECT";
export const UPDATE_PROJECT = "UPDATE_PROJECT";


export const GET_CLIENTS = "GET_CLIENTS";
export const DELETE_CLIENT = "DELETE_CLIENT";
export const ADD_CLIENT = "ADD_CLIENT";
export const GET_CLIENT = "GET_CLIENT";
export const UPDATE_CLIENT = "UPDATE_CLIENT";

export const GET_SUBCONTRACTORS = "GET_SUBCONTRACTORS";
export const GET_SUBCONTRACTOR = "GET_SUBCONTRACTOR";
export const ADD_SUBCONTRACTOR = "ADD_SUBCONTRACTOR";
export const DELETE_SUBCONTRACTOR = "DELETE_SUBCONTRACTOR";
export const UPDATE_SUBCONTRACTOR = "UPDATE_SUBCONTRACTOR";

export const GET_SUBCONTRACTOR_INVOICES = "GET_SUBCONTRACTOR_INVOICES";
export const GET_SUBCONTRACTOR_INVOICE = "GET_SUBCONTRACTOR_INVOICE";
export const ADD_SUBCONTRACTOR_INVOICE = "ADD_SUBCONTRACTOR_INVOICE";
export const DELETE_SUBCONTRACTOR_INVOICE = "DELETE_SUBCONTRACTOR_INVOICE";
export const UPDATE_SUBCONTRACTOR_INVOICE = "UPDATE_SUBCONTRACTOR_INVOICE";

export const GET_PURCHASES = "GET_PURCHASES";
export const GET_PURCHASE = "GET_PURCHASE";
export const ADD_PURCHASE = "ADD_PURCHASE";
export const DELETE_PURCHASE = "DELETE_PURCHASE";
export const UPDATE_PURCHASE = "UPDATE_PURCHASE";

export const GET_MISC_PURCHASES = "GET_MISC_PURCHASES";
export const GET_MISC_PURCHASE = "GET_MISC_PURCHASE";
export const ADD_MISC_PURCHASE = "ADD_MISC_PURCHASE";
export const DELETE_MISC_PURCHASE = "DELETE_MISC_PURCHASE";
export const UPDATE_MISC_PURCHASE = "UPDATE_MISC_PURCHASE";

export const GET_LABOURS = "GET_LABOURS";
export const GET_LABOUR = "GET_LABOUR";
export const ADD_LABOUR = "ADD_LABOUR";
export const DELETE_LABOUR = "DELETE_LABOUR";
export const UPDATE_LABOUR = "UPDATE_LABOUR";


export const GET_CLIENT_INVOICES = "GET_CLIENT_INVOICES";
export const DELETE_CLIENT_INVOICE = "DELETE_CLIENT_INVOICE";
export const ADD_CLIENT_INVOICE = "ADD_CLIENT_INVOICE";
export const GET_CLIENT_INVOICE = "GET_CLIENT_INVOICE";
export const UPDATE_CLIENT_INVOICE = "UPDATE_CLIENT_INVOICE";

export const GET_ERRORS = "GET_ERRORS";
export const CREATE_MESSAGE = "CREATE_MESSAGE";
export const GET_MESSAGES = "GET_MESSAGES";

export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const USER_LOADING = "USER_LOADING";
export const USER_LOADED = "USER_LOADED";
export const GET_USERS = "GET_USERS";
export const AUTH_ERROR = "AUTH_ERROR";

export const BASE_URL = process.env.REACT_APP_API_URL;
export const TASK_STATUS_TYPES = {

  'planning': 'Planning',
  'in_progress': 'In Progress',
  'completed': 'Completed',
  'on_hold': 'On Hold',
};
